export const baseContent = {
  backgroundImg: "camila-home.jpg",
  arrowImage: "mock-desktop.png",
  supportImage: "photo-of-people-having-fist-bump.png",
  lastArrowImage: "digital.png",
  titleText: "Microcrédito 100% digital para microempreendedores negativados",
  titleColor: "#fff",
  textColor: "#fff",
  carrousselImg: "setp-five-desktop.png",
  supportText: "Empréstimo facilitado para microempreendedores. Vamos te ajudar a acelerar o seu negócio!",
};