import React, { useState } from "react";
import { navigate } from "gatsby"
import { 
  Container, 
  TextContainer, 
  Title, 
  SecondaryTitle, 
  Text, 
  Content, 
  Image,
  Button,
  CloseButton,
  CancelImage,
  ContainerMobile, 
  TextContainerMobile, 
  SecondaryTitleMobile, 
  TextMobile, 
  ContentMobile, 
  ImageMobile,
  ButtonMobile,
  CloseButtonMobile,
  CancelImageMobile,
} from "./styles";
import Woman from "../../images/ImgBanner.png";
import WomanMobile from "../../images/ImgBannerMobile.png";
import Close from "../../images/cancel.png";

function IndividualCreditBanner() {

  const [focused, setFocused] = useState(true);
  function CloseModal(){
    setFocused(false);
  }
  return (
    <>
    <Container focused={focused}>
      <Content>
        <Image src={Woman} />
        <CloseButton onClick={CloseModal}>
            <CancelImage src={Close}/>
        </CloseButton>
        <TextContainer>
          <Title>Já sabe da novidade?</Title>
          <SecondaryTitle>Agora a Veriza também<br />
            oferece crédito individual!
          </SecondaryTitle>
          <Text>
            Empréstimo facilitado para autônomos.<br />
            A Veriza vai te ajudar a acelerar o seu <br />
            negócio!
          </Text>
          <Button onClick={() => navigate('https://credito.veriza.com.br/')}>
            <Text>Peça o seu crédito</Text>
          </Button>
        </TextContainer>
      </Content>
    </Container>

    <ContainerMobile focused={focused}>
      <ContentMobile>
        <ImageMobile src={WomanMobile} />
        <CloseButtonMobile onClick={CloseModal}>
            <CancelImageMobile src={Close}/>
        </CloseButtonMobile>
        <TextContainerMobile>
          <SecondaryTitleMobile>Agora a Veriza também<br />
            oferece crédito individual!
          </SecondaryTitleMobile>
          <TextMobile>
            Empréstimo facilitado para autônomos.<br />
            A Veriza vai te ajudar a acelerar o seu <br />
            negócio!
          </TextMobile>
          <ButtonMobile onClick={() => navigate('https://credito.veriza.com.br/')}>
            <TextMobile>Peça o seu crédito</TextMobile>
          </ButtonMobile>
        </TextContainerMobile>
      </ContentMobile>
    </ContainerMobile>
    </>
  )
}

export default IndividualCreditBanner;