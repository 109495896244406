import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  display: none;
  ${media.greaterThan("medium")`
    display: ${({ focused }) => (focused ? 'flex' : 'none')};
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    position: fixed;
    z-index: 1;
    background-color: rgba(50, 50, 50, 0.5);
  `}
`
export const Content = styled.div`
  display: none;
  ${media.greaterThan("medium")`
    display: flex;
    flex: 1;
    background-color: #FFFFFF;
    opacity: 1;
    position: absolute;
    margin-top: 40px;
  `}
`;

export const TextContainer = styled.div`
  display: none;
  ${media.greaterThan("medium")`
    display: flex;
    flex: 1;
    height: 80%;
    flex-direction: column;
    position: absolute;
    margin-top: 40px;
    margin-left: 20px;
    justify-content: space-between;
  `}
`;

export const Image = styled.img`
  display: none;
  ${media.greaterThan("medium")`
  display: flex;
    width: 800px;
    height: 560px;
  `}
`;

export const CancelImage = styled.img`
  display: none;
  ${media.greaterThan("medium")`
  display: flex;
    position: absolute;
    width: 16px;
    height: 16px;
  `}
`;

export const Title = styled.p`
  display: none;
  ${media.greaterThan("medium")`
  display: flex;
    font-size: 2.125rem;
    font-weight: 800;
    color: ${props => props.theme.colors.greenDarker}; 
  `}
`

export const SecondaryTitle = styled.p`
  display: none;
  ${media.greaterThan("medium")`
  display: flex;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 40px;
    color: ${props => props.theme.colors.greenDarker};
  `}
`

export const Text = styled.p`
  display: none;
  ${media.greaterThan("medium")`
  display: flex;
    font-size: 1.25rem;
    font-weight: 500;
    color: ${props => props.theme.colors.greenDarker};
  `}
`

export const CloseButton = styled.div`
  display: none;
  ${media.greaterThan("medium")`
  display: flex;
    position: absolute;
    flex: 1;
    width: 100%;
    justify-content: flex-end;
    padding: 15px;
  `}
`;

export const Button = styled.div`
  display: none;
  ${media.greaterThan("medium")`
    display: flex;
    border-radius: 10px;
    height: 60px;
    width: 410px;
    background-color: ${props => props.theme.colors.green};
    justify-content: center;
    align-items: center;
    align-self: center;

    :hover {
      background-color: ${props => props.theme.colors.green};
      opacity: 0.5;
      cursor: pointer;
    }

    p {
      display: block;
      font-size: 22px;
      font-weight: 800;
      color: ${props => props.theme.colors.white};
    }
  `}
`

export const ContainerMobile = styled.div`
  display: ${({ focused }) => (focused ? 'flex' : 'none')};
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: fixed;
  z-index: 1;
  background-color: rgba(50, 50, 50, 0.5);
  ${media.greaterThan("medium")`
    display: none;
  `}
`
export const ContentMobile = styled.div`
  display: flex;
  flex: 1;
  background-color: #FFFFFF;
  opacity: 1;
  position: absolute;
  margin-top: 40px;
  ${media.greaterThan("medium")`
    display: none;
  `}
`;

export const TextContainerMobile = styled.div`
  display: flex;
  flex: 1;
  height: 80%;
  flex-direction: column;
  position: absolute;
  margin-top: 40px;
  margin-left: 20px;
  justify-content: flex-end;
  ${media.greaterThan("medium")`
    display: none;
  `}
`;

export const ImageMobile = styled.img`
display: flex;
  width: 100%;
  height: 100%;
  ${media.greaterThan("medium")`
    display: none;
  `}
`;

export const CancelImageMobile = styled.img`
display: flex;
  position: absolute;
  width: 16px;
  height: 16px;
  ${media.greaterThan("medium")`
    display: none;
  `}
`;

export const SecondaryTitleMobile = styled.p`
display: flex;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  margin-bottom: 15px;
  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const TextMobile = styled.p`
display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const CloseButtonMobile = styled.div`
  display: flex;
  position: absolute;
  flex: 1;
  width: 100%;
  justify-content: flex-end;
  padding: 15px;
  ${media.greaterThan("medium")`
    display: none;
  `}
`;

export const ButtonMobile = styled.div`
  display: flex;
  border-radius: 10px;
  height: 48px;
  width: 100%;
  background-color: ${props => props.theme.colors.greenDarker};
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 10px;

  :hover {
    background-color: ${props => props.theme.colors.green};
    opacity: 0.5;
    cursor: pointer;
  }

  p {
    display: block;
    font-size: 22px;
    font-weight: 800;
    color: ${props => props.theme.colors.white};
  }
  ${media.greaterThan("medium")`
    display: none;
  `}
`
